import PropTypes from "prop-types"
import React from "react"
import {Button, Container, Nav, Navbar} from "react-bootstrap";
import logo from '../images/buzzvert_logo.png';
import {Link} from "gatsby";

const Header = ({ siteTitle }) => (
  <Navbar collapseOnSelect expand="md" bg="light">
    <Container>
      <Navbar.Brand href="/">
        <img
          src={logo}
          height="25"
          className="d-inline-block align-top"
          alt="Buzzvert"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Link href="/#how-it-works">How it Works</Nav.Link>
          <Nav.Link href="/#features">Features</Nav.Link>
          <Nav.Link className="mr-3" href="/influencers">Explore</Nav.Link>
        </Nav>
        <Button as={Link} to="/influencers?modal=true" variant="outline-primary">Sign Up</Button>
      </Navbar.Collapse>
    </Container>
  </Navbar>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
