import React from 'react'
import {Container} from "react-bootstrap";
import logo from "../images/buzzvert_logo.png";
import {Link} from "gatsby";

const Footer = () => (
	<footer className="bg-light p-3 p-md-5">
		<Container>
			<div className="d-flex justify-content-between flex-column flex-md-row">
				<div>
					<ul className="list-unstyled list-inline font-weight-bold mb-1">
						<li className="list-inline-item"><Link to="/#how-it-works" className="text-dark">How it Works</Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/influencers" className="text-dark">Discover Influencers</Link></li>
						<li className="list-inline-item ml-md-3"><Link to="/#features" className="text-dark">Features</Link></li>
						<li className="list-inline-item ml-md-3"><a className="text-dark" href="mailto:info@buzzvert.com">Contact</a></li>
					</ul>
					<p className="text-muted">
						<small>Designed and built with <span className="text-danger">♥</span> in Amsterdam © Buzzvert 2020</small>
					</p>
				</div>
				<div>
					<img
						src={logo}
						height="25"
						className="d-inline-block align-top mb-2"
						alt="Buzzvert"
					/>
				</div>
			</div>
		</Container>
	</footer>
);

export default Footer;